<template>
	<div class="container" style="padding-bottom: 50px;">
		<div class="top" style="display:flex;justify-content: space-between;">
			<span>问候语</span>
			<el-switch v-model="ruleForm.EnableGreeting" @change="ChangeEnableGreeting" style='margin-left:10px;'></el-switch>
		</div>

		<el-form :model="ruleForm" class="content" ref="ruleForm" label-width="180px">
			<el-form-item label="待接入会话回复问候语：">
				<el-switch v-model="ruleForm.EnableHoldGreeting" :disabled="!ruleForm.EnableGreeting" style='margin-left:10px;'></el-switch>
				<div style="font-size: 14px;color: #999;">开启后，待接入会话的客户离线后再次访问客服，系统给客户回复问候语；关闭后，不回复</div>
			</el-form-item>
			<el-form-item label="已接入会话回复问候语：">
				<el-switch v-model="ruleForm.EnableAccessGreeting" :disabled="!ruleForm.EnableGreeting" style='margin-left:10px;'></el-switch>
				<div style="font-size: 14px;color: #999;">开启后，已接入会话的客户离线后再次访问客服，系统给客户回复问候语；关闭后，不回复</div>
			</el-form-item>
			<el-form-item label="在线客服首次问候语：">
				<el-input v-model="ruleForm.Greetings" type="textarea" maxlength="500" placeholder="最多输入500个字；若为空，将不发放问候语" :rows="10"
				 style="width: 730px;"></el-input>
			</el-form-item>
			<el-form-item label="发送图片">
				<div style="color: #999;font-size: 13px;">最多可添加10张图片，添加多张图片时，每次随机发送其中一张</div>
				<div class="filter-item" style="vertical-align: top;">
					<upload-img @transPicture="transPictureFun" :AttachedImgUrls="ruleForm.GreetingImgUrls" :Type="4"></upload-img>
				</div>
			</el-form-item>
			<el-form-item label="前台展示示例：">
				<img src='http://cdn.dkycn.cn/images/melyshop/mlmz-costomer-show.png' />
			</el-form-item>
		</el-form>

		<div class="footer">
			<el-button style="width:240px;" type="primary" @click="saveSupiler('ruleForm')" :loading="loading">保存</el-button>
		</div>
	</div>
</template>

<script>
	import config from '@/config/index'
	import uploadImg from '@/components/uploadImgs/uploadPicture';
	// import apiList from '@/api/other'
	import {
		xjmallplatformgateway,
	} from '@/api/goods'
	export default {
		components: {
			uploadImg,
		},
		data() {
			return {
				imgUrl: config.IMG_BASE,
				imgApi: config.UPLOAD_IMG,
				loading: false,
				ruleForm: {
					Greetings: '',
					EnableGreeting: false,
					EnableHoldGreeting: false,
					EnableAccessGreeting: false,
					GreetingImgUrls: []
				},
			}
		},
		created() {
			this.getData()
		},
		methods: {
			ChangeEnableGreeting(){
				if(!this.ruleForm.EnableGreeting){
					this.ruleForm.EnableHoldGreeting = false
					this.ruleForm.EnableAccessGreeting = false
				}
			},
			// 批量上传的图片
			transPictureFun(datas) {
				this.ruleForm.GreetingImgUrls = datas;
			},
			async getData() {
				try {
					let data = {
						ApiPath: '/ChatAutoReply/index',
						RequestBody: {
							"OrderByDesc": "Id",
							"Take": 10,
							"Skip": 0
						}
					}
					let result = await xjmallplatformgateway(data)
					this.ruleForm.Greetings = result.Result.Data.Greeting
					this.ruleForm.EnableGreeting = result.Result.EnableGreeting
					if(!this.ruleForm.EnableGreeting){
						this.ruleForm.EnableHoldGreeting = false
						this.ruleForm.EnableAccessGreeting = false
					}
					else{
						this.ruleForm.EnableHoldGreeting = result.Result.EnableHoldGreeting
						this.ruleForm.EnableAccessGreeting = result.Result.EnableAccessGreeting
					}
					
					if (result.Result.GreetingImgUrls && result.Result.GreetingImgUrls.length) {
						var InitImgurl = []
						InitImgurl = result.Result.GreetingImgUrls.map(item => {
							if (item.indexOf(this.imgUrl) > -1) {
								item = item.replace(this.imgUrl, '')
							}
							return item
						})
						this.ruleForm.GreetingImgUrls = InitImgurl
					} else {
						this.ruleForm.GreetingImgUrls = []
					}
					
					
				} catch (e) {

				}
			},
			async saveSupiler() {
				try {
					this.loading = true;
					var newImgurl = []
					if (this.ruleForm.GreetingImgUrls.length) {
						newImgurl = this.ruleForm.GreetingImgUrls.map(item => {
							if (item.indexOf(this.imgUrl) == -1) {
								item = this.imgUrl + item
							}
							return item

						})
					}
					
					if (newImgurl.length > 10) {
						newImgurl.splice(10)
					}
					let data = {
						ApiPath: '/ChatAutoReplyCategory/UpdateGreeting',
						RequestBody: {
							Greeting: this.ruleForm.Greetings,
							EnableGreeting: this.ruleForm.EnableGreeting,
							EnableHoldGreeting: this.ruleForm.EnableHoldGreeting,
							EnableAccessGreeting: this.ruleForm.EnableAccessGreeting,
							GreetingImgUrls: newImgurl
						}
					}
					let result = await xjmallplatformgateway(data)
					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '保存成功!'
						});
						this.getData()
					}

				} catch (e) {
					//TODO handle the exception
					console.log(e)
				} finally {
					this.loading = false
				}

			}
		}
	}
</script>

<style lang="less" scoped>
	.container {
		background-color: #fff;

		.top {
			line-height: 40px;
			border-bottom: 1px solid #ddd;
			padding: 10px;
		}

		.content {
			padding: 20px 0;
		}

		.footer {
			line-height: 80px;
			background-color: #fff;
			position: fixed;
			bottom: 0px;
			width: 100%;
			text-align: center;
		}
	}
</style>
